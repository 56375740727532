import {Component, OnDestroy, OnInit} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {NgxSpinnerService} from 'ngx-spinner';
import {first, forkJoin, pipe, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {EventMessageType} from './core/models/event-message-type.enum';
import {CustomerInfoService} from './core/services/customer-info.service';
import {LanguagesService} from './core/services/languages.service';
import {ModeService} from './core/services/mode.service';
import {SseEventsService} from './core/services/sse-events.service';
import {UserService} from './core/services/user.service';
import {ConnectedUser, UserLoggedService,} from './core/services/userLogged.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@UntilDestroy()
export class AppComponent implements OnInit, OnDestroy {
  public isLoaded: boolean = false;
  public isTermsChecked: boolean = false;
  private onDestroy$: Subject<void> = new Subject();

  private connectedUser: ConnectedUser;

  constructor(
    private readonly modeService: ModeService,
    private readonly spinner: NgxSpinnerService,
    private readonly userLoggedService: UserLoggedService,
    private readonly userService: UserService,
    private readonly customerService: CustomerInfoService,
    private readonly sseEvents: SseEventsService,
    private readonly langService: LanguagesService,
    private readonly keycloak: KeycloakService
  ) {
  }

  ngOnInit() {
    this.spinner.show().then();
    this.connectedUser = this.userLoggedService.loadCurrentUser();
    this.isTermsChecked = this.connectedUser.eulaStatus;
    forkJoin([
      this.userService.getMe(),
      this.modeService.fetchAllModes(),
      this.customerService.fetchCustomerInfo(), this.langService.init()
    ])
      .pipe(
        pipe(first())
      )
      .subscribe(() => {
        this.subscribeToLogOutEvent();
        this.isLoaded = true;
        this.spinner.hide();
      });

  }

  ngOnDestroy() {
    this.sseEvents.stopListening();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private subscribeToLogOutEvent(): void {

    this.sseEvents.startListening();

    this.sseEvents
      .on(EventMessageType.SSO_LOGOUT_EVENT)
      .pipe(
        untilDestroyed(this),
        tap((event) => {
          if (event) {
            this.keycloak.logout().then();
          }
        })
      )
      .subscribe();

  }


  public receiveIsTermsChecked(checked: boolean): void {
    this.isTermsChecked = checked;
  }
}
