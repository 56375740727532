<bs-header [isDarkTheme]="isDarkTheme$()"
           [languages]="languages$()"
           [currentLanguage]="currentLanguage$()"
           [notifications]="notifications$()"
           settingsUrl="/me/settings"
           [username]="currentUser$()?.username"
           (languageChange)="languageChange($event)"
           (readNotification)="readNotification($event)"
           (logout)="logout()"
           (darkThemeToggle)="toggleDarkTheme()">

    <ng-container navItems>
        <bs-header-nav-item label="GLOBAL.GLOBAL.WORKFLOW" url="workflow"></bs-header-nav-item>
        <bs-header-nav-item label="GLOBAL.GLOBAL.ADMINISTRATION" url="admin"></bs-header-nav-item>
        <bs-header-nav-item label="GLOBAL.GLOBAL.HELP" url="help"></bs-header-nav-item>
    </ng-container>
</bs-header>

